import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './logo.svg';
import event from './events.svg';
import mic from './mic.svg';
import text from './text.svg';
import title from './title.svg';
import lineup from './lineuptbd.svg';

const PAGE_STATE = {
    HOME: 'HOME',
    ARTISTS: 'ARTISTS',
    MIC: 'MIC',
    LINEUP: 'LINEUP',
};

export default function Events()
{
    const [menuOpen, setMenuOpen] = useState(false);
    const [activePage, setActivePage] = useState(PAGE_STATE.HOME);
    const navigate = useNavigate();

    const handleNavigation = (page) =>
    {
        setActivePage(page);
        if (page === PAGE_STATE.ARTISTS)
        {
            navigate('/event');
        } else if (page === PAGE_STATE.MIC)
        {
            navigate('/openmic');
        }
        else if (page === PAGE_STATE.HOME)
        {
            navigate('/');
        }
    };


    return (
        <div className="bg-white w-full h-full overflow-y-auto">
            {/* Navigation Buttons */}
            <div className='min-h-screen w-full flex flex-col'>
                <header className="bg-white shadow-md fixed top-0 w-full z-50">
                    <div className="container mx-auto flex justify-between items-center py-5 sm:px-5 px-3">
                        <div className='flex gap-2.5'>
                            <img src={logo} alt='' className='w-auto h-[33px]' />
                            <h1 className="sm:text-2xl text-lg font-bold">Jora</h1>
                        </div>
                        <button
                            className="lg:hidden text-gray-700"
                            onClick={() => setMenuOpen(!menuOpen)}
                        >
                            ☰
                        </button>
                        <nav
                            className={`${menuOpen ? 'flex' : 'hidden'
                                } flex-col bg-white lg:shadow-none shadow-md space-y-3 lg:flex lg:flex-row lg:space-y-0 lg:space-x-6 items-start lg:items-center text-xs lg:text-sm absolute top-full left-0 w-full lg:static lg:w-auto lg:bg-transparent py-5 lg:py-0 justify-start lg:justify-center px-10 lg:px-0`}
                        >
                            <button
                                onClick={() => handleNavigation(PAGE_STATE.HOME)}
                                className="text-gray-700 hover:text-[#4F46E5]"
                            >
                                Features
                            </button>
                            <div className="hidden h-2 w-2 rounded-full bg-gray-700 lg:block" />
                            <button className="text-gray-700 hover:text-[#4F46E5]"
                                onClick={() => handleNavigation(PAGE_STATE.ARTISTS)}
                            >Events</button>
                            {/* <button className="text-gray-700 hover:text-[#4F46E5]"
                                onClick={() => handleNavigation(PAGE_STATE.MIC)}
                            >
                                Open Mic Sign Up
                            </button> */}
                            <button
                                onClick={() => handleNavigation(PAGE_STATE.HOME)}
                                className="bg-[#4F46E5] text-white px-4 py-2 rounded-md hover:bg-purple-700 transition"
                            >
                                Sign up for beta
                            </button>
                        </nav>
                    </div>
                </header>
                <div
                    className="relative flex-grow min-h-screen flex justify-center w-full"
                    style={{
                        backgroundImage: `url(${event})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                >
                    <div className='flex items-center justify-center w-full lg:pt-32'>
                        <div className='flex flex-col pt-24 lg:pt-0  gap-y-6 justify-start items-center z-20 h-full w-full px-4'>
                            <div className="flex justify-center w-full px-2 md:px-10">
                                <img src={title} alt="" className="h-auto w-[95%] sm:w-[95%] xl:w-full max-w-full" />
                            </div>
                            <div className="flex justify-end w-full px-8 sm:px-10 md:px-16">
                                <p className="text-white text-right" style={{ fontSize: 'clamp(14px, 2vw + 1vh, 54.4px)' }}>
                                    RSVPs are closed and capacity's reached! Free sign-ups may be available at the door—we’ll do our best to accommodate, so come early!
                                </p>
                            </div>
                            <div className="flex flex-col items-end w-full px-8 sm:px-10 md:px-16">
                                <p className="text-white" style={{ fontSize: 'clamp(14px, 1.5vw + 1vh, 54.4px)' }}>
                                    Friday Nov. 15
                                </p>
                                <p className="text-white" style={{ fontSize: 'clamp(14px, 1.5vw + 1vh, 54.4px)' }}>
                                    7:00 - 9:30
                                </p>
                                <p className="text-white" style={{ fontSize: 'clamp(14px, 1.5vw + 1vh, 54.4px)' }}>
                                    540 Midvale Rooftop
                                </p>
                            </div>
                            {/* Netlify RSVP Form */}
                            {/* <form name="rsvp" method='post' id='form' className="z-20 flex flex-col items-center sm:items-end w-full px-8 sm:px-10 md:px-16" style={{ fontSize: 'clamp(14px, 2vw + 1vh, 54.4px)' }}>
                                <input type="hidden" name="form-name" value="rsvp" />
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    required
                                    className="px-4 py-2 rounded-full w-full sm:w-[300px] lg:w-[500px] bg-white text-black mb-3"
                                    placeholder="Enter your name"
                                    style={{ fontSize: 14 }}
                                />
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    required
                                    className="px-4 py-2 rounded-full w-full sm:w-[300px] lg:w-[500px] bg-white text-black"
                                    placeholder="Enter your email"
                                    style={{ fontSize: 14 }}
                                />

                                <button
                                    type="submit"
                                    className="px-6 py-3 bg-[#D0D5FF]/90 w-full sm:w-[300px] lg:w-[500px] text-white rounded-full hover:bg-blue-600 mt-4"
                                    style={{ fontSize: 14 }}
                                >
                                    RSVP
                                </button>
                            </form> */}
                            {/* <div className="z-20 flex flex-col -mt-6 items-center sm:items-end w-full px-8 sm:px-10 md:px-16" style={{ fontSize: 'clamp(14px, 2vw + 1vh, 54.4px)' }}>
                                <button
                                    className="px-6 py-3 bg-[#D0D5FF]/90 w-full sm:w-[300px] lg:w-[500px] text-white rounded-full hover:bg-blue-600 mt-4"
                                    style={{ fontSize: 'clamp(14px, 1.5vw + 1vh, 54.4px)' }}
                                    onClick={() => handleNavigation(PAGE_STATE.MIC)}
                                >
                                    Open Mic Sign Up
                                </button>
                            </div> */}
                            <div className="flex justify-end items-center w-full md:w-[70%] self-end px-8 sm:px-10 md:px-16">
                                <p className="text-white text-center sm:text-right" style={{ fontSize: 'clamp(14px, 3vw + 1vh, 54.4px)' }}>
                                    <span style={{ fontSize: 'clamp(16px, 4vw + 1vh, 60.4px)' }}>Line Up: </span> Tiffany Phan <span style={{ fontSize: 'clamp(12px, 3vw, 44.4px)' }}>& Layla Price</span>, keyofgigi, Kobe Mehki, Mila Mour
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='h-full'>
                        <div className="absolute bottom-0 left-0 flex pl-3 md:pl-10 lg:pl-10 z-10">
                            <img src={mic} alt="" className="opacity-60 h-auto w-[60%] md:w-[70%] lg:w-[80%] xl:w-[90%] max-w-full" />
                        </div>
                        <div className="absolute bottom-20 lg:bottom-32 left-0 z-10 pl-3 md:pl-10 lg:pl-10 text-white">
                            <img src={text} alt="" className="h-auto w-[40%] md:w-[50%] lg:w-[60%] xl:w-[70%] max-w-full" />
                        </div>
                    </div>
                </div>
                <div className="w-full px-[20px] lg:px-[120px]">
                    <div className="flex flex-col text-center py-[83px] px-5">
                        <div className='flex flex-col sm:flex-row items-center border-b pb-5 gap-2'>
                            <div className='flex flex-col items-center sm:items-start gap-y-1 w-full sm:w-[80%]'>
                                <img src={logo} alt='' className='w-auto h-[33px]' />
                                <p className="text-xs text-center sm:text-left text-black">
                                    Jora is an all-in-one platform that connects artists, venues, and crew for seamless live music event management and discovery.
                                </p>
                            </div>
                            <div className='flex justify-center items-center w-full sm:w-[20%]'>
                                <button onClick={() => handleNavigation(PAGE_STATE.HOME)}

                                    className="bg-[#4F46E5] text-white px-4 py-2 rounded-md hover:bg-purple-700 transition text-xs lg:text-sm">
                                    Sign up for beta
                                </button>
                            </div>
                        </div>
                        <div className='pt-5'>
                            <p className="text-xs text-center md:text-left text-black">
                                Copyright © 2024 Jora. All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
